/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var ajaxUrl = themevars.ajaxUrl;

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  var clock;
  var clock_section = $('.flipclock-section');
  if( $(clock_section).length ){
    var seconds = $(clock_section).attr('data-seconds-lef');

    clock = $(clock_section).FlipClock({
        clockFace: 'DailyCounter',
        autoStart: true,
        showSeconds: false,
        callbacks: {
            stop: function() {
                $('.message').html('The clock has stopped!');
            }
        }
    });
    clock.setTime(+seconds);
    clock.setCountdown(true);
    clock.start();
  }

  var testimonialMaxHeight = function(){
    var text_block = $('.testimonial .max-height');
    $.each(text_block, function(){
      var height = $(this).parent().find('img').first().height();
      if($(this).height() > height){
        var inner_block = $(this).children('.vc_column-inner');
        $(inner_block).addClass('limit-height');
        $(this).append('<p class="testimonial-toggle"><a href="#">Read More...</a></p>');
      }
      //console.log(height);
    });
  };

  testimonialMaxHeight();

  $(document).on('click', '.testimonial-toggle a', function(e){
    e.preventDefault();
    console.log( $(this).parent().closest('.limit-height') );
    $(this).parent().siblings('.limit-height').removeClass('limit-height');
    $(this).remove();
  });

  $('#award-id').on('change', function(){
    var select = $(this);
    $('#additional-question').remove();
    var data = {
      action: 'load_question',
      award_id: $(this).val()
    };

    jQuery.post( ajaxUrl, data, function(response) {
      $(select).parent().after('<div id="additional-question">' + response + '</div>');
    });
  });

  $('#nomination-award-id').on('change', function(){
    var select = $(this);
    $('#additional-question').remove();
    var data = {
      action: 'load_nomination',
      award_id: $(this).val()
    };

    jQuery.post( ajaxUrl, data, function(response) {
      $(select).parent().after('<div id="additional-question">' + response + '</div>');
    });
  });

  $('.banner .fa-bars').on('click', function(){
    $(this).hide();
    $(this).siblings('.fa-times').show();
    $(this).siblings('.menu-main-menu-container').slideToggle();
    // Dynamically sets top margin of mobile menu
  });
  $('.banner .fa-times').on('click', function(){
    $(this).hide();
    $(this).siblings('.fa-bars').show();
    $(this).siblings('.menu-main-menu-container').slideToggle();
  });

  $('.owl-carousel').owlCarousel({
    loop:true,
    margin:0,
    nav:false,
    dots:false,
    autoHeight: false,
    autoplay:true,
    autoplayTimeout:2000,
    responsive:{
        0:{
            items:2
        },
        768:{
            items:3
        }
    }
  })

})(jQuery); // Fully reference jQuery after this point.
